<template>
    <div class="template-page blog">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">

            <h3>Filter by</h3>
            <div class="filtri">
                <div class="tags-filter">
                    <button type="checkbox" @click="addFilter" :class="{'image-tag': true, 'active': checkActive(t)}"
                        v-for="(t, index) in this.tagsArray" :key="'tag'+index" :value="t"
                        name="filtertag">{{ t }}</button>
                </div>

                <div class="ricerca-testo">
                    <input v-model="research" placeholder="Search the content...">
                </div>
            </div>

            <div v-if="filteredBlog.length > 0">

                <h2 class="title-section">The Latest</h2>
                <div class="section-1">
                    <router-link :to="articleLink(this.filteredBlog[0])">
                        <large-blog-tile :articleData="this.filteredBlog[0]"></large-blog-tile>
                    </router-link>
                </div>

                <h2 class="title-section" v-if="this.filteredBlog.length > 1">Recent posts</h2>
                <div class="section-2" v-if="this.filteredBlog.length > 1">
                    <router-link v-if="this.filteredBlog.length > 1" :to="articleLink(this.filteredBlog[1])">
                        <blog-tile :articleData="this.filteredBlog[1]"></blog-tile>
                    </router-link>
                    <router-link v-if="this.filteredBlog.length > 2" :to="articleLink(this.filteredBlog[2])">
                        <blog-tile :articleData="this.filteredBlog[2]"></blog-tile>
                    </router-link>
                </div>

                <div class="section-3" v-if="this.filteredBlog && this.filteredBlog.length > 3">
                    <router-link v-for="(art, index) in this.loadedBlog" :key="'article'+index" :to="articleLink(art)">
                        <blog-tile :articleData="art"></blog-tile>
                    </router-link>
                </div>

            </div>

            <br>
            <div class="loadmore" v-if="this.filteredBlog.length > this.loaded+3">
                <button class="cta" @click="loadMore()"><span>Load More</span></button>
            </div>
        </basic-page>
    </div>
</template>

<script>
    import BlogTile from '../components/BlogTile.vue'
    import LargeBlogTile from '../components/LargeBlogTile.vue'
    import BasicPage from '../views/BasicPage.vue'
    import {
        fetchSingleNode,
        fetchNodes
    } from '../libs/drupalClient'
    import {
        detailsLink
    } from '../libs/utils'

    export default {
        name: 'blog',
        components: {
            BlogTile,
            LargeBlogTile,
            BasicPage
        },
        data: () => {
            return {
                pageData: {},
                fulldata: {},
                blogData: [],
                currentPageId: "2262573e-274d-44b3-9172-34e2db37c225",
                loaded: 3,
                tagsArray: [],
                filteringTags: [],
                research: '',
                bannerimg: null
            }
        },
        methods: {
            loadMore() {
                this.loaded += 6;
            },
            checkActive(t) {
                return this.filteringTags.includes(t)
            },
            addFilter(event) {
                if (this.filteringTags.includes(event.target.value))
                    this.filteringTags.splice(this.filteringTags.indexOf(event.target.value), 1)
                else
                    this.filteringTags.push(event.target.value)
                //console.log(this.filteringTags)
            },
            articleLink(a) {
                //console.log(a)
                return detailsLink('article', a)
            }
        },
        computed: {
            loadedBlog() {
                return this.filteredBlog.slice(3, this.loaded + 3)
            },
            filteredBlog() {
                var b = []
                if (this.filteringTags.length > 0) {
                    b = this.blogData
                        .filter(n => {
                            var r = false;
                            n.field_article_type.forEach(s => {
                                if (this.filteringTags.includes(s.attributes.name))
                                    r = true
                            })
                            return r
                        })
                } else {
                    b = this.blogData
                }

                return b.filter(n => {
                    return (
                        n.attributes.title.toLowerCase().includes(this.research.toLowerCase()) ||
                        n.attributes.body[0].processed.toLowerCase().includes(this.research.toLowerCase())
                    )
                })
            }
        },
        mounted() {
            fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] 
            }).then(res => {
                this.pageData = res[0].attributes
                this.fulldata = res[0]
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })
            fetchNodes('article', {
                include: ['field_image', 'field_ref_author', 'field_article_type']
            }).then(res => {
                //console.log('articoli', res)
                this.blogData = res.sort(
                    (a, b) => {
                        return a.attributes.field_pubblication_date < b.attributes.field_pubblication_date ?
                            1 : -1
                    }
                )

                this.blogData.forEach(n => {
                    n.field_article_type.forEach(t => {
                        if (!this.tagsArray.includes(t.attributes.name))
                            this.tagsArray.push(t.attributes.name)
                    })
                })
            })
        }
    }
</script>

<style lang="scss" scoped>

</style>