<template>
    <div class="blogtile-wrapper large-tile angle yellow-angle"
        :style="{
        background: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABHNCSVQICAgIfAhkiAAAAC9JREFUKFNj5HJd+P/b7nhGBiggxIcrhGkgRBNtMsxm0m0g5GZ0edJtIDZ0RrQfAKWpSA0cPR/rAAAAAElFTkSuQmCC), url(' + imgSource + ')' 
        }">
        <div class="tag">{{ this.articleData.field_article_type[0].attributes.name }}</div>
        <div class="content">
            <div class="info">
                <div class="date">{{ newDate }}</div>

            </div>
            <div class="title">
                {{ this.articleData.attributes.title }}
            </div>
            <div class="body" v-html="this.articleData.attributes.body[0].summary.length>200 ? 
                    (this.articleData.attributes.body[0].summary.substr(0, 200) + '...') : 
                    this.articleData.attributes.body[0].summary 
                "></div>
            <div class="author" v-for="(a, index) in this.articleData.field_ref_author" :key="'author'+index">
                {{ a.attributes.field_first_name + ' ' + a.attributes.field_last_name }}
            </div>
        </div>
    </div>
</template>

<script>
    import {
        convertMonthShort
    } from '../libs/utils'

    export default {
        name: 'large-blog-tile',
        data: () => {
            return {

            }
        },
        props: {
            articleData: Object
        },
        methods: {

        },
        computed: {
            newDate() {
                var d = new Date(Date.parse(this.articleData.attributes.field_pubblication_date))
                return d.getDate() + ' ' + convertMonthShort(d.getMonth()) + ' ' + d.getFullYear()
            },
            imgSource() {
                if (this.articleData.field_image)
                    return process.env.VUE_APP_ENDPOINT + this.articleData.field_image.attributes.uri.url
                else
                    return 'collection-newspapers.jpg'
            }
        },
        mounted() {
            //console.log(this.articleData)
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/colors.scss";

    .blogtile-wrapper.large-tile:hover {
        transition: 0.3s ease;

        &::before {
            transition: 0.3s ease;
            opacity: 0.9;
        }

        .title {
            color: lighten($mainColor, $amount: 10%);
            transition: 0.3s ease;
        }
    }

    .angle::after {
        top: 40px;
        right: 40px;
    }

    .large-tile {
        position: relative;
        padding: 3rem 5rem;
        border-radius: 0px 0px 40px 0px;
        min-height: 370px;


        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            border-radius: 0px 0px 40px 0px;
            left: 0;
            transition: 0.3s ease;
            background: linear-gradient(-90deg, rgb(18 30 51 / 37%), rgb(18 30 51 / 85%));
        }
    }

    a {
        text-decoration: none;
        height: 100%;
    }

    .content {
        position: relative;
        display: block;
        margin: 0;
        width: 60%;

        .title {
            transition: 0.3s ease;
            color: $mainColor;
            //bottom: 150px;
            z-index: 100;
            top: 80px;
            max-height: 150px;
            font-weight: 500;
            //left: 40px;
            font-size: 2.4em;
        }

        .body {
            max-height: 120px;
            overflow: hidden;
            z-index: 100;
            bottom: 60px;
            color: #fff;
            font-size: 1.4em;
            margin: 1rem 0rem;
        }

        .info {
            top: 0;
            font-size: 1.3rem;

            .date,
            .author div {
                font-size: 1.5rem;
                color: #fff;
                font-weight: 300;
            }

            .date {
                font-size: 1.25rem;
                color: rgb(204, 204, 204);
            }

        }

        .author {
            margin-top: 1rem;
            color: $mainColor;
        }
    }

    .tag {
        bottom: 0;
        left: 0;
        background: $mainColor;
        padding: .5em 2em;
        font-size: 1.2em;
        text-transform: uppercase;
        position: absolute;
        font-weight: 700;

    }


    @media (max-width: 1200px) {
        .large-tile {
            position: relative;
            padding: 3rem 5rem 5rem 5rem;
        }
    }

    @media (max-width: 992px) {
        .large-tile {
            position: relative;
            padding: 2rem 4rem 4rem 3rem;

            .content {
                width: auto;
            }
        }
        .tag {
            font-size: 0.875rem;
        }
    }

    @media (max-width: 768px) {
        .large-tile .content .body {
            font-size: 1em;
            line-height: 1.89rem;
        }
    }

    @media (max-width: 576px) {
        .large-tile {
            padding: 2rem 2rem 5rem 2rem;

            .content {
                .title {
                    font-size: 1.7em;
                    max-height: 200px;
                    overflow: auto;

                }

                .info {
                    flex-direction: column;
                    top: -70px;
                    font-size: 1em;

                    .date {
                        margin: 0;
                    }
                }
            }
        }
    }
</style>