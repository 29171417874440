<template>
    <div class="blogtile-wrapper">
        <div class="image angle yellow-angle">
            <img v-if="articleData && imgSource!=''" :src="imgSource" alt="">
            <img v-if="articleData && imgSource==''" src="../assets/collection-newspapers.jpg" alt="">
            <div class="tags">
                <div v-for="(a, index) in this.articleData.field_article_type" :key="'tag'+index" class="tag">
                    {{ a.attributes.name }}</div>
            </div>
        </div>
        <div class="content">
            <div class="title">
                {{ this.articleData.attributes.title.length>70? 
                    (this.articleData.attributes.title.substr(0, 67) + '...') :
                    this.articleData.attributes.title
                }}
            </div>
            <div class="description" v-if="this.articleData.attributes.body">
                {{ this.articleData.attributes.body[0].summary.length>150 ? 
                    (this.articleData.attributes.body[0].summary.substr(0, 150) + '...') : 
                    this.articleData.attributes.body[0].summary 
                }}
            </div>
            <div class="info">
                <div class="date">
                    <div>
                        <span>
                            {{ rewritedate }}
                        </span>
                    </div>
                </div>
                <div class="author">
                    <div v-for="(a, index) in this.articleData.field_ref_author" :key="'author'+index">
                        <span> {{ '- ' + a.attributes.field_first_name + ' ' + a.attributes.field_last_name + (index!=articleData.field_ref_author.length-1 ? ', ' : '')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        convertMonthShort
    } from '../libs/utils'
    export default {
        name: 'blog-tile',
        data: () => {
            return {
            }
        },
        props: {
            articleData: Object
        },
        methods: {
        },
        computed: {
            imgSource() {
                if (this.articleData.field_image)
                    return process.env.VUE_APP_ENDPOINT + this.articleData.field_image.attributes.uri.url
                else
                    return ''
            },
            rewritedate() {
                var d = new Date(Date.parse(this.articleData.attributes.field_pubblication_date))
                return d.getDate() + ' ' + convertMonthShort(d.getMonth()) + ' ' + d.getFullYear()
            }
        },
        mounted() {
            //console.log(this.articleData)
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/colors.scss";

    .info{
        div{
            display: flex;
        }
        span {
            font-family: "T-Star Pro";
            font-weight: 400;
            color: #333333;
            font-size: 1rem;
        }
    }

</style>